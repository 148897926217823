<template>
  <v-row>
    <v-col class="pt-0">
      <skeleton-groups v-if="!['AccountGroupsCreate', 'AccountGroupsUpdate'].includes($route.name) && (loadingDetail || loadingMembers)" />
      <template v-else>
        <v-col class="px-0" style="z-index: 0;">
          <div class="mt-0 pa-0 d-flex">
            <div class="pa-0" style="flex:0; min-width: 350px;">
              <div style="position:fixed!important;">
                <v-card class="pa-0 pb-16 white-0" flat width="350" height="calc(100vh - 220px)">
                  <v-toolbar color="transparent" flat height="46">
                    <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                      <v-row align="center" no-gutters>
                        <v-icon size="18" left color="grey-500">mdi-account-group</v-icon>
                        Rol
                      </v-row>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="pa-0" style="min-height: calc(100vh - 420px);">
                  <!-- form-->
                    <template v-if="currentStep === 'inf'">
                      <skeleton-groups v-if="loadingDetail" class="mt-5" type="inf" />
                      <v-list class="transparent pt-2 px-5" v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Nombre</v-list-item-title>
                            <v-list-item-subtitle>
                              <v-text-field v-model="$v.name.$model" :error="$v.name.$error" @input="isChangeName = true" :disabled="$route.name !== 'AccountGroupsCreate' ? !$helpers.hasPermission(['change_group']) : false" outlined single-line dense hide-details />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="mt-0">
                          <v-list-item-content>
                            <v-list-item-title class="align-self-start">Descripción</v-list-item-title>
                            <v-list-item-subtitle>
                              <v-textarea class="textarea-users" v-model="$v.description.$model" :error="$v.description.$error" :height="90" @input="isChangeDescription = true" :disabled="$route.name !== 'AccountGroupsCreate' ? !$helpers.hasPermission(['change_group']) : false" outlined single-line dense hint="(Máximo 150 caracteres)" persistent-hint />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                  <!-- end form-->
                  </v-card-text>
                  <template v-if="$route.name === 'AccountGroupsUpdate'">
                    <v-divider class="mx-6" :class="{'mt-n7' : loadingDetail}" />
                    <v-card-actions class="px-5 pt-4" style="display: block">
                      <p class="d-block body-1 grey-300--text">
                        Al eliminar el rol, todos los usuarios pertenecientes a este quedarán sin permisos activos.
                      </p>
                      <v-btn v-if="$helpers.hasPermission(['delete_member'])" @click="dialogDelete=true" outlined style="border: 1px solid var(--light-red-500)">
                        <div class="red-500--text d-flex align-center">
                          <v-icon color="red-500">mdi-delete</v-icon>
                          Eliminar rol
                        </div>
                      </v-btn>
                    </v-card-actions>
                  </template>
                </v-card>
              </div>
            </div>
            <div class="pa-0 pb-16 ml-8" style="flex:1; min-width: 350px;">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="subtitle-1 grey-500--text font-weight-semibold">
                  <v-row align="center" no-gutters>
                    <v-icon size="18" left color="grey-500">mdi-lock</v-icon>
                    Permisos del rol
                  </v-row>
                </v-toolbar-title>
                <v-spacer />
              <v-toolbar-items>
                <v-row align="center" justify="center" class="mr-3" v-if="$route.name === 'AccountGroupsUpdate'">
                  <v-btn @click="dialogUsers=true" outlined>
                    <v-icon left>mdi-account-group</v-icon>
                    Lista de usuarios
                  </v-btn>
                </v-row>
              </v-toolbar-items>
              </v-toolbar>
              <v-col class="pa-0" v-if="loadingDetail">
                <v-skeleton-loader class="skeleton-group rounded-t-md" tile transition="fade-transition" type="image" :height="200" />
                <v-divider/>
                <v-skeleton-loader class="skeleton-group" tile transition="fade-transition" type="image" :height="200" />
                <v-divider/>
                <v-skeleton-loader class="skeleton-group rounded-b-md" tile transition="fade-transition" type="image" :height="200" />
              </v-col>
              <v-row class="fill-height mt-2" v-else align="start" no-gutters>
                <v-col class="px-0">
                  <v-card class="pa-0 white-0" :class="item.parent ? roundedByCategory(item.category) : 'my-5'" flat v-for="(item, i) in list" :key="i">
                    <div>
                      <template v-if="item.parent === 'Configuraciones' && item.category === 'Organización'">
                        <v-toolbar color="transparent" flat height="46">
                          <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                            <v-row align="center" no-gutters>
                              <v-icon size="18" left color="grey-500">mdi-file-document</v-icon>
                              Configuración
                            </v-row>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-divider />
                      </template>
                      <template v-if="item.parent === 'Recepción' && item.category === 'Documentos'">
                        <v-toolbar color="transparent" flat height="46">
                          <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                            <v-row align="center" no-gutters>
                              <v-icon size="18" left color="grey-500">mdi-file-document</v-icon>
                              Recepción
                            </v-row>
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-divider />
                      </template>
                      <v-toolbar color="transparent" flat height="46">
                        <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">
                          <v-row align="center" no-gutters>
                            <v-icon size="18" left color="grey-500">mdi-{{ item.icon }}</v-icon>
                            {{item.category === 'SII' ? $t('generals.SII') : item.category}}
                          </v-row>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-divider />
                      <v-card-text class="pa-0">
                        <v-col class="px-5">
                          <skeleton-groups v-if="loadingDetail" type="permissions" />
                          <template v-else>
                            <v-col class="pa-0" v-for="(c, index) in item.children" :key="index">
                              <v-list class="v-list-1 transparent pa-0">
                                <v-list-item class="pa-0">
                                  <v-list-item-title class="grey-300--text">
                                    <span v-if="c.key !== 'dtes'">{{c.name}}</span>
                                    <template v-if="['Clientes'].includes(c.category)">
                                      <v-chip class="caption pa-2" color="background" text-color="#9FA8DA" x-small v-if="c.name.includes('Ver') && item.children[index - 1]?.active">
                                        <span :class="{'d-inline-block text-truncate': $vuetify.breakpoint.width < 1310}" :style="`max-width: ${$vuetify.breakpoint.width < 1310 ? '300px' : 'auto'};`">
                                          Obligatorio, si la opción {{item.children[index - 1].name.toLowerCase()}} está activa
                                        </span>
                                      </v-chip>
                                    </template>
                                    <template v-if="c.id === 'add_document'">
                                      <v-tooltip v-if="selectOrDeselectAllPermission || !!currentDocumentList.filter((item) => item[`${item.id}__add_document`]).length" color="white-0" right max-width="185px" transition="scale-transition">
                                        <template v-slot:activator="{ on }">
                                          <span class="" v-on="on">
                                            <v-icon class="ml-2" small color="grey-300--text">mdi-information</v-icon>
                                          </span>
                                        </template>
                                        <span class="d-block px-3 py-2"> También se habilitará el permiso de ver emisión</span>
                                      </v-tooltip>
                                    </template>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <v-switch
                                      class="mt-n1"
                                      v-model="c.active"
                                      v-if="c.key !== 'dtes'"
                                      color="success"
                                      :ripple="false"
                                      hide-details
                                      @change="isChangeList = true, ['Clientes'].includes(c.category) && c.active ? item.children[index + 1].active = true : ''" />
                                  </v-list-item-subtitle>
                                </v-list-item>
                                <div class="mt-n7" v-if="c.key === 'dtes'">
                                  <v-list-item class="pa-0" v-for="(doc, i) in currentDocumentList" :key="i" inactive>
                                    <v-list-item-title class="grey-300--text">{{doc.name}}
                                      <v-chip class="caption pa-2" color="background" text-color="#9FA8DA" x-small v-if="doc.name.includes('Ver') && currentDocumentList[i - 1].active">
                                        <span :class="{'d-inline-block text-truncate': $vuetify.breakpoint.width < 1310}" :style="`max-width: ${$vuetify.breakpoint.width < 1310 ? '300px' : 'auto'};`">
                                          Obligatorio, si la opción {{currentDocumentList[i - 1].name.toLowerCase()}} está activa
                                        </span>
                                      </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="mr-0">
                                      <v-switch class="mt-n1" v-model="doc.active" @click="enabledTooltip(doc, i, index), item.selectOrDeselectAllPermission = currentDocumentList.every((item) => item.active), isChangeList = true" color="green-500" :ripple="false" :disabled="doc.name.includes('Ver') && currentDocumentList[i - 1].active" hide-details />
                                    </v-list-item-subtitle>
                                  </v-list-item>
                                  <v-col :class="['pa-0 mt-2', {'mb-6' : $store.state.auth.account.country !== 'PE'}]" v-if="item.category === 'Emisión'">
                                    <v-list class="v-list-1 transparent pa-0">
                                      <v-list-item class="pa-0">
                                        <v-list-item-title class="font-weight-bold grey-300--text">Seleccionar todos los DTE’s</v-list-item-title>
                                        <v-list-item-subtitle>
                                          <v-switch v-model="item.selectOrDeselectAllPermission" class="mt-n1" @change="selectAllPermissions(item.category, $event), isChangeList = true" color="green-500" :ripple="false" hide-details />
                                        </v-list-item-subtitle>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                  <div class="mt-n3" v-if="$store.state.auth.account.country !== 'PE'" style="border-top: 1px solid var(--light-blue-grey); position: absolute; width: 100%; left: 0; right: 0"></div>
                                </div>
                              </v-list>
                            </v-col>
                          </template>
                        </v-col>
                      </v-card-text>
                      <v-divider v-if="list.length -1 !== i && (['Configuraciones'].includes(item.parent) || (['Documentos', 'Emisión por lotes', 'Emisión', 'Recepción', 'Proveedores'].includes(item.category) && $store.state.auth.account.country !== 'PE'))" />
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </template>
    </v-col>
    <div class="text-end white-0 mt-6 pa-0 pagination-permissions">
      <v-divider />
      <v-col class="px-0">
        <v-btn
          class="px-4"
         :to="{ name: 'AccountGroups', params: this.$route.params}"
          outlined
        >Cancelar</v-btn>
        <v-btn
          class="px-4 ml-2 mr-6"
          @click="$route.name === 'AccountGroupsCreate' ? create() : update()"
          :loading="$route.name === 'AccountGroupsCreate' ? loading : loadingUpdate"
          :disabled="(!isChangeName && !isChangeDescription) && !isChangeList"
          color="blue-500"
        >
          Guardar rol
        </v-btn>
      </v-col>
    </div>

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="751" persistent no-click-animation overlay-color="grey-500" :scrollable="true">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Eliminar rol</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <v-row align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-confirm.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-0">
              <span class="d-block">Al eliminar el rol, todos los usuarios pertenecientes a este quedarán sin permisos activos.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="destroyGroup" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog users -->
    <v-dialog v-model="dialogUsers" width="600" persistent no-click-animation overlay-color="grey-500" :scrollable="false">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Lista de usuarios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="handlerUserDialog" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text :class="['pa-0 grey-700--text aling-center', {'d-flex' : !currentMembersList.length}]">
          <skeleton-list v-if="loadingMembers" />
          <template v-else>
            <v-data-table
              v-if="currentMembersList.length"
              class="users-table"
              :headers="headers"
              :items="currentMembersList"
              hide-default-header
              :items-per-page="currentMembersList.length"
              disable-sort
              fixed-header
              height="450"
              hide-default-footer
            >
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="soting()" />
                <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.first_name`]="{item}">
                <span class="fotn-weight-medium body-1" v-if="item.first_name || item.last_name">{{item.first_name}} {{ item.last_name }}</span>
                <span class="body-1 text--disabled font-italic" v-else>Sin especificar</span>
                <span class="d-block body-1 grey-300--text body-users" v-if="item.email">{{ item.email }}</span>
              </template>
              <template v-slot:[`item.joined_at`]="{item}">
              {{item.joined_at | dateTime}}
              </template>
              <template v-slot:[`item.actions`]="{item}">
              <v-btn @click="$router.replace({ name: 'AccountMemberUpdate', params: { id: item.id } })" text color="blue-500">Ir al usuario</v-btn>
              </template>
            </v-data-table>
            <v-empty-state v-else class="my-16" type="users" id="miembros" :isFree="true" title="No existen usuarios" :height="180" customClass="mt-5" />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn v-if="currentMembersList.length" @click="dialogExport=true" outlined>Descargar listado</v-btn>
          <v-btn color="blue-500" @click="handlerUserDialog">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog users -->

    <!-- dialog export -->
    <v-dialog v-model="dialogExport" width="439" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Descarga listado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogExport=false, type = ''" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="grey-700--text body-1 font-weight-medium">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="mt-n1">
                  <div class="d-flex justify-sm-space-between">
                    <div class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-radio-group v-model="type" dense hide-details>
                        <v-radio :label="file" :value="file" />
                      </v-radio-group>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false, type = ''" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createExport()" :ripple="false" :disabled="!type.length">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog export -->
  </v-row>
</template>
<script>
// import cloneDeep from 'lodash/cloneDeep'
import VEmptyState from '@/components/commons/VEmptyState'
import permissionList from '@/collections/permissionsList'
import { groupBy, orderBy } from 'lodash'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import SkeletonGroups from '@/modules/account/views/groups/components/SkeletonGroups'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import { required, maxLength } from 'vuelidate/lib/validators'
import { saveAs } from 'file-saver'

export default {
  components: {
  SkeletonGroups,
  SkeletonList,
  VTableHeaders,
  VEmptyState
},
  mixins: [
  GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    dialogExport: false,
    headers: [
      { text: 'Nombre', value: 'first_name', sortable: true, width: 150 },
      { text: 'Fecha de ingreso al rol', value: 'joined_at', align: 'end' },
      { text: '', value: 'actions', align: 'end' }
    ],
    isCreated: false,
    isChangeList: false,
    dialogUsers: false,
    confirm: '',
    dialogDelete: false,
    panel: [],
    selectOrDeselectAllPermissionDTE: false,
    isNotError: true,
    permissionList,
    changePermissions: false,
    changeMembers: false,
    list: [],
    summaryList: [],
    resetSwitch: 0,
    loading: false,
    loadingMembers: false,
    loadingDetail: false,
    selectOrDeselectAllPermission: false,
    currentMembersList: [],
    permissionsList: [],
    currentCategory: '',
    currentStep: 'inf',
    nextStep: 0,
    isChangeName: false,
    isChangeDescription: false,
    loadingUpdate: false,
    name: '',
    description: '',
    documentTypes: DocumentTypes,
    currentDocumentList: [],
    stepperList: [
      {
        status: 'inf',
        title: 'Información',
        icon: 'information-outline',
        permissionRequired: []
      },
      {
        status: 'permissions',
        title: 'Permisos',
        icon: 'cog-outline',
        permissionRequired: []
      },
      {
        status: 'members',
        title: 'Miembros',
        icon: 'account',
        permissionRequired: ['view_member']
      },
      {
        status: 'finalized',
        title: 'Resumen',
        icon: 'check-bold',
        permissionRequired: []
      }
    ],
    loadingUpdateGroup: false,
    typeSort: 'asc',
    parents: {
      Configuraciones: ['Organización', 'Usuarios', 'Roles', 'Developers', 'Notificaciones'],
      Emisión: ['Emisión', 'Emisión por lotes', 'Clientes'],
      Recepción: ['Documentos', 'Proveedores', 'Grupo de proveedores']
    },
    fileFormat: ['CSV', 'XLSX'],
    type: ''
  }),
  computed: {
  ...mapState({
    membersList: state => state.accounts.membersList,
    groupsList: state => state.accounts.groupsList,
    groupDetail: state => state.accounts.groupsDetail
  }),
    activeSave () {
      return !this.list?.filter((item) => item.children?.every((item) => item.active)).length
    },
    disabledNext () {
      if (this.nextStep === 0) {
        return this.$route.name === 'AccountGroupsUpdate' ? !((this.isChangeName || this.isChangeDescription) && !this.$v.name.$invalid) : this.$v.name.$invalid || this.$v.description.$invalid
      }
      return !this.activeSave ? !!this.activeSave : this.activeOnlyPermissionSave
    },
    roundedByCategory () {
      return (category) => {
        if (['Roles', 'Usuarios', 'Notificaciones', 'Emisión por lotes', 'Proveedores'].includes(category)) return 'rounded-0'
        if (['Organización', 'Emisión', 'Documentos'].includes(category)) return 'rounded-b-0'
        if (['Developers', 'Clientes', 'Grupo de proveedores'].includes(category)) return 'rounded-t-0'
        if (['Grupo de proveedores'].includes(category)) return 'rounded-t-0 mb-5'

        else return ''
      }
    }
  },
  watch: {
    // list: {
    //   handler () {
    //     if (!this.isCreated) this.isChangeList = true
    //   },
    //   inmediate: true,
    // },
    currentDocumentList: {
      handler () {
        if (!this.isCreated) this.isChangeList = true
      },
      inmediate: false
    }
  },
  mounted () {
    if (this.$route.query.users) {
      this.dialogUsers = true
    }
  },
  created () {
    this.isCreated = true
    this.permissionsList = JSON.parse(JSON.stringify(permissionList))
    this.permissionsList = this.$store?.state?.auth?.account?.suppliergroup_feature ? this.permissionsList : [...this.permissionsList.filter(({category}) => !['Grupo de proveedores'].includes(category))]

    if (['AccountGroupsUpdate'].includes(this.$route.name)) {
      this.loadingDetail = true
      this.$store.dispatch('accounts/RETRIEVE', {
        resource: 'groups',
        id: this.$route.params.id,
        loader: this.$route.name === 'AccountGroupsUpdate'
      })
      .then((response) => {
        const { name, description, permissions, members } = response.data
        this.name = name
        this.description = description
        this.list = [...this.permissionsList]
        this.list.forEach((item) => {
          if (permissions.includes(item.id)) {
            item.active = true
          }
        })
        this.list = [...this.groupBy(this.list)]
        /** set documentType */

        this.setPermissionsByDTEGroups(response.data.document_type_permissions)
        /** end set documentType */
        this.currentMembersList = members
      })
      .finally(() => {
        this.loadingDetail = false
        this.isCreated = false
      })
    } else {
      this.setPermissionsByDTEGroups()
    }
  },
  methods: {
    createExport () {
      this.dialogExport = false
      this.$store.dispatch('accounts/LIST', {
        resource: `groups/${this.$route.params.id}/export_members/${this.type.toLowerCase()}`,
        loader: true,
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const content = Buffer.from(response.data, 'base64')
        saveAs(new Blob([content], {type: `application/${this.type.toLowerCase()}`}), `Listado de miembros.${this.type.toLowerCase()}`)
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error en la descarga')
      })
      .finally(() => {
        this.type = ''
      })
    },
    handlerUserDialog () {
      this.dialogUsers = false
      if (this.$route.query.users) {
        this.$router.replace({ query: null }).catch(() => {})
      }
    },
    setPermissionsByDTEGroups (list = []) {
      this.currentDocumentList = []
      let aux = []
      this.list = this.groupBy([...this.permissionsList])
      let array = [...this.documentTypes?.filter(({country}) => this.$store?.state?.auth?.account?.country === country)]
      array.forEach((item) => {
        aux = [...aux, ...[`Crear ${item.name.toLowerCase()}`, `Ver ${item.name.toLowerCase()}`]]
      })
      aux.forEach((a) => {
        array.forEach((item) => {
          if (item.name.toLowerCase() === a.replace('Ver', '').trimStart()) {
            this.currentDocumentList = [...this.currentDocumentList, {...item, ...{name: a, active: list?.some((doc) => doc.document_type === item.id && doc.permission === 'view_document') ?? false}}]
          } else if (item.name.toLowerCase() === a.replace('Crear', '').trimStart()) {
            this.currentDocumentList = [...this.currentDocumentList, {...item, ...{name: a, active: list?.some((doc) => doc.document_type === item.id && doc.permission === 'add_document') ?? false}}]
          }
        })
      })

      this.list.forEach((item) => {
        if (item.category !== 'Emisión') item.selectOrDeselectAllPermission = item.children.every(({active}) => active)
        else {
          item.selectOrDeselectAllPermission = this.currentDocumentList.every(({active}) => active)
        }
      })
    },
    soting () {
      this.typeSort = this.typeSort === 'asc' ? 'desc' : 'asc'
      this.currentMembersList = orderBy(this.currentMembersList, ['first_name'], [this.typeSort])
    },
    destroyGroup () {
      this.$store.dispatch('accounts/DESTROY', {
        resource: 'groups',
        id: this.$route.params.id
      })
      .then(() => {
        this.$dialog.message.info('El grupo se ha sido eliminado con éxito')
        this.$router.replace({ name: 'AccountGroups' }).catch(() => {})
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDelete = false
        this.confirm = ''
      })
    },
    enabledTooltip (doc = {}) {
      if (doc.name.includes('Crear')) {
        const current = doc.name.replace('Crear', '').trimStart()
        const index = this.currentDocumentList.findIndex(({name}) => name === `Ver ${current}`)
        if (index !== -1 && doc.active) {
          this.currentDocumentList[index].active = true
          this.currentDocumentList[index].activeTooltip = true
        } else {
          this.currentDocumentList[index].activeTooltip = false
        }
      }
    },
    groupBy (list = []) {
      return Object.entries(groupBy(list, 'category')).map(([key, value]) => {
        return {
          category: key,
          icon: value[0].icon,
          selectOrDeselectAllPermission: false,
          parent: value.find(({parent}) => this.parents[parent]?.includes(key))?.parent ?? '',
          children: value.filter(({countries}) => countries.includes(this.$store?.state?.auth?.account?.country))
        }
      }).filter(({children}) => children.length)
    },
    selectedCategory (item) {
      this.currentCategory = item.category
    },
    selectAllPermissions (category, e) {
      this.list.forEach((item) => {
        if (item.category === category && category !== 'Emisión') {
          item.children.forEach((c) => {
            c.active = e
          })
        }
      })
      if (this.currentDocumentList.length && category === 'Emisión') {
        this.currentDocumentList.forEach((d) => {
          d.active = e
        })
      }
    },
    async create () {
      this.$v.name.$touch()
      this.$v.description.$touch()

      if (this.$v.name.$invalid || this.$v.description.$invalid) {
        return false
      }

      let permissionsList = []
      this.list.forEach((item) => {
        permissionsList = [...permissionsList, ...item.children?.filter(({active}) => active).map(({id}) => id)]
      })

      permissionsList.forEach((item) => {
        if (item === 'add_document') permissionsList.push('view_document')
      })

      this.loading = true
      let payload = {
        name: this.name,
        description: this.description,
        permissions: permissionsList
      }
      let documentTypePermissions = []
      if (this.currentDocumentList.length) {
        this.currentDocumentList.forEach((item) => {
          if (item.active) {
            documentTypePermissions.push({
              document_type: item.id,
              permission: item.name.includes('Crear') ? 'add_document' : 'view_document'
            })
          }
        })
      }
      payload = { ...payload, ...{document_type_permissions: documentTypePermissions} }

      this.$store.dispatch('accounts/CREATE', {
        resource: 'groups',
        payload
      })
      .then(async (response) => {
        this.$dialog.message.info('El grupo se ha guardado con éxito')
        this.$router.push({ name: 'AccountGroups', params: this.$route.params })
      })
      .finally(() => {
        this.loading = false
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    update () {
      this.loadingUpdateGroup = true
      let documentTypePermissions = []
      if (this.currentDocumentList.length) {
        this.currentDocumentList.forEach((item) => {
            if (item.active) {
            documentTypePermissions.push({
              document_type: item.id,
              permission: item.name.includes('Crear') ? 'add_document' : 'view_document'
            })
          }
        })
      }
      let permissionsList = []
      this.list.forEach((item) => {
        permissionsList = [...permissionsList, ...item.children?.filter(({active}) => active).map(({id}) => id)]
      })

      this.$store.dispatch('accounts/UPDATE', {
        resource: 'groups',
        id: this.$route.params.id,
        payload: {
          name: this.name,
          description: this.description,
          permissions: permissionsList,
          document_type_permissions: documentTypePermissions
        }
      })
      .then((response) => {
        const { name, description, permissions } = response.data
        this.name = name
        this.description = description
        this.list = [...this.permissionsList]
        this.list.forEach((item) => {
          if (permissions.includes(item.id)) {
            item.active = true
          }
        })
        this.list = this.groupBy(this.list)
        this.$dialog.message.info('El grupo ha sido actualizado con éxito')
        this.$router.push({ name: 'AccountGroups', params: this.$route.params })
      })
      .catch((error) => {
        this.isNotError = false
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loadingUpdateGroup = false
      })
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(32)
    },
    description: {
      maxLength: maxLength(150)
    }
  }
}
</script>